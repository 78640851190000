import './App.css';

function App() {
  return (
    <div className="App">
      <h2>Privacy Policy for "Melbourne Train app"</h2>
      <h4>Effective Date: 10th May, 2024</h4>
      Melbourne Train is owned and operated by Hung Nguyen.
      <h3>Purpose</h3>
      The purpose of this privacy policy (this “Privacy Policy”) is to inform users of our app of the following:
      <li>The personal data we will collect;</li>
      <li>Use of collected data;</li>
      <li>This Privacy Policy applies in addition to the terms and conditions of our mobile app.</li>
      <h3>Consent</h3>
      By using our app users agree that they consent to:
      <li>The conditions set out in this Privacy Policy; and</li>
      <li>The collection, use, and retention of the data listed in this Privacy Policy.</li>
      <h3>How We Use Personal Data</h3>
      Data collected on our app will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our app. We will not use your data beyond what we disclose in this Privacy Policy.
      <h3>Other Disclosures</h3>
      We will not sell or share your data with other third parties, except in the following cases:
      <li>If the law requires it;</li>
      <li>If it is required for any legal proceeding;</li>
      <li>To prove or protect our legal rights; and</li>
      <li>To buyers or potential buyers of this company if we seek to sell the company.</li>
      <h3>How Long We Store Personal Data</h3>
      <h3></h3>
      <div>User data will be stored until the purpose of the data collected for has been achieved.</div>
      <h3>How We Protect Your Data</h3>
      While we take all reasonable precautions to ensure that user data is secure and that users at protected, there always remains the risk of harm. The Internet as a whole can be insecure a times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.
      <h3>Modifications</h3>
      This Privacy Policy may be amended from time to time in order to maintain compliance w the law and to reflect any changes to our data collection process.
      When we amend this Privacy Policy we will update the “Effective Date” at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.
    </div>
  );
}

export default App;
